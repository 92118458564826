<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        a) Consider the phase diagram for carbon dioxide shown below. What state would carbon
        dioxide be in at a temperature of
        <stemble-latex content="$35.0\,^\circ\text{C}$" />
        and a pressure of
        <stemble-latex content="$85.0\ \text{atm?}$" />
      </p>

      <p class="pl-8 mb-5">
        <v-img src="/img/assignments/cpp1210s4q6.png" max-width="417px" />
      </p>

      <p class="mb-4">
        Phase:
        <v-select
          v-model="inputs.input1"
          class="d-sm-inline-block ml-3 mb-3"
          style="width: 193px"
          dense
          :items="options"
          label="Select Option"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n3">
        <s-textarea v-model="inputs.textResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemCPP1210S4_Q7',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        textResponse: null,
      },
      options: [
        {
          text: 'Solid',
          value: 'solid',
        },
        {
          text: 'Liquid',
          value: 'liquid',
        },
        {
          text: 'Gas',
          value: 'gas',
        },
        {
          text: 'Supercritical Fluid',
          value: 'sCFluid',
        },
      ],
    };
  },
};
</script>
